<template>
  <LayoutHome :contentData="memo">
  <main class="bg-grey-300">
    <div
      :class="!dataloaded ? 'flex items-center justify-center home bg-faintGrey min-h-screen p-8' : ''"
    >
      <div class="mt-20" v-if="!dataloaded && !error">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" pserveAspectRatio="xMidYMid">
          <g transform="translate(26.666666666666668,26.666666666666668)">
            <rect x="-20" y="-20" width="40" height="40" fill="#000000">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.3s"></animateTransform>
            </rect>
          </g>
          <g transform="translate(73.33333333333333,26.666666666666668)">
            <rect x="-20" y="-20" width="40" height="40" fill="#636363">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.2s"></animateTransform>
            </rect>
          </g>
          <g transform="translate(26.666666666666668,73.33333333333333)">
            <rect x="-20" y="-20" width="40" height="40" fill="#e1e1e1">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="0s"></animateTransform>
            </rect>
          </g>
          <g transform="translate(73.33333333333333,73.33333333333333)">
            <rect x="-20" y="-20" width="40" height="40" fill="#545454">
              <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="1.1500000000000001;1" begin="-0.1s"></animateTransform>
            </rect>
          </g>
        </svg>
      </div>
      <div v-if="!dataloaded && error" class=" my-10">
        <div class="flex flex-col justify-center items-center">
          <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="43.5" cy="43.5" r="43.5" fill="#F4F5F5"/>
            <path d="M48.9422 55.3909C48.9422 58.392 46.5005 60.8337 43.4994 60.8337C40.4983 60.8337 38.0566 58.392 38.0566 55.3909C38.0566 52.3898 40.4983 49.9481 43.4994 49.9481C46.5005 49.9481 48.9422 52.3898 48.9422 55.3909ZM38.6866 27.7144L39.6119 46.2198C39.6554 47.0888 40.3726 47.771 41.2427 47.771H45.7561C46.6262 47.771 47.3434 47.0888 47.3869 46.2198L48.3122 27.7144C48.3588 26.7817 47.6152 26 46.6814 26H40.3174C39.3836 26 38.64 26.7817 38.6866 27.7144Z" fill="#828282" fill-opacity="0.6"/>
           </svg>
          <p class="w-[210px] mt-4 font-Rubik text-[13px] leading-[20px] text-greyText text-center">Unable to load memo! This memo does not exist</p>
        </div>
      </div>
      <div v-if="dataloaded" class="home min-h-screen p-8">
        <div class="overview bg-white rounded-[4px] px-8 py-9" id="overview">
          <div class="flex items-start md:flex-row flex-col w-full mb-3">
            <div class="logo w-[80px] h-[80px] mr-4">
              <img :src="`${s3Bucket}${memo.logo}`" alt="">
            </div>
            <div class="relative w-[inherit]">
              <div class="header flex items-center justify-between">
                <h1 class="text-[24px] leading-[32px] mb-[6px] tracking-[-2%] font-Playfair">{{ memo.title}}📌📌</h1>
                <img src="../assets//images/kinfolk-black.svg" alt="" class="w-[24px] h-[21.06px]">
              </div>
              <h2 class="mb-[12px] font-[500] text-[16px] leading-[24px] font-Playfair">{{ memo.company_name }}</h2>
              <p class="flex items-center text-greyText mb-[20px] text-[12px] leading-[20px] font-Playfair">
                <span class="mr-[6px]">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1C5.23969 1 3 3.01594 3 5.5C3 9.5 8 15 8 15C8 15 13 9.5 13 5.5C13 3.01594 10.7603 1 8 1ZM8 8C7.60444 8 7.21776 7.8827 6.88886 7.66294C6.55996 7.44318 6.30362 7.13082 6.15224 6.76537C6.00087 6.39991 5.96126 5.99778 6.03843 5.60982C6.1156 5.22186 6.30608 4.86549 6.58579 4.58579C6.86549 4.30608 7.22186 4.1156 7.60982 4.03843C7.99778 3.96126 8.39991 4.00087 8.76537 4.15224C9.13082 4.30362 9.44318 4.55996 9.66294 4.88886C9.8827 5.21776 10 5.60444 10 6C9.99942 6.53026 9.78852 7.03863 9.41357 7.41357C9.03863 7.78852 8.53026 7.99942 8 8Z" fill="#666666"/>
                  </svg>
                </span>
                {{ memo.formatted_address}}
              </p>
              <div class="flex items-center flex-wrap">
                <div class="bg-faintGrey text-greyText text-[14px] leading-[20px] py-[6px] px-[12px] max-w-[max-content] min-w-[122.59px] mr-3 mb-3 rounded-[4px]">
                  Year founded: <span class="text-black">{{memo.year_founded}}</span>
                </div>
                <div class="bg-faintGrey text-greyText text-[14px] leading-[20px] py-[6px] px-[12px] max-w-[max-content] min-w-[122.59px] mr-3 mb-3 rounded-[4px]">
                  Industry: <span class="text-black">{{memo.industry_type}}</span>
                </div>
                <div class="bg-faintGrey text-greyText text-[14px] leading-[20px] py-[6px] px-[12px] max-w-[max-content] min-w-[122.59px] mr-3 mb-3 rounded-[4px]">
                  Business model: <span class="text-black">{{memo.business_model}}</span>
                </div>
                <div class="bg-faintGrey text-greyText text-[14px] leading-[20px] py-[6px] px-[12px] max-w-[max-content] min-w-[122.59px] mr-3 mb-3 rounded-[4px]">
                  No of employees: <span class="text-black"> {{memo.num_employees}}</span>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div id="company-overview" class="pt-6 relative">
            <h3 class="text-[16px] leading-[24px] mb-3 font-Playfair font-bold">Company Overview</h3>
            <div v-html="memo.company_summary" class="text-[14px] leading-[26px] text-greyText"></div>
          </div>
        </div>

        <div class="relative">
          <div class="bg-white rounded-[4px] p-8 mt-8">
            <div class="relative">
              <div id="team" class="border-b-[1px] border-black py-[6px] text-[18px] font-bold leading-[24px] mb-4 font-Playfair">
                Team
              </div>
              <ul class="mb-6 list-disc ml-4 text-black text-[14px]">
                <li v-for="(member, id) in memo.team" :key="id">{{member.name}} - {{member.role}}</li>
              </ul>
            </div>
            <div class="relative">
              <div id="team" class="border-b-[1px] border-black py-[6px] text-[18px] font-bold leading-[24px] mb-4 font-Playfair">
                Team Details
              </div>
              <div v-if="memo.team_details" v-html="memo.team_details" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <span v-else class="block mb-6 text-greyText text-sm tracking-[0.1px] pr-2">No data available. Check back later.</span>
            </div>
            <div id="market_opportunity" class="relative mb-12">
              <div class="border-b-[1px] border-black py-[6px] text-[18px] leading-[24px] mb-4 font-Playfair font-bold">
                Market Opportunity
              </div>
              <div v-if="memo.market_opportunity" v-html="memo.market_opportunity" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <span v-else class="block mb-6 text-greyText text-sm tracking-[0.1px] pr-2">No data available. Check back later.</span>

              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.market_opportunity_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="customer_pricing" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Customer & Pricing
              </div>
              <div v-if="memo.customer_and_pricing" v-html="memo.customer_and_pricing" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.customer_and_pricing_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-12 relative">
              <div id="product" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Product
              </div>
              <div v-html="memo.product" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.product_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="customers_acquisition_retention" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Customers Acquisition & Retention
              </div>
              <div v-html="memo.acquisition_and_retention" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.acquisition_and_retention_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="competition" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Competition
              </div>
              <div v-html="memo.competition" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.competition_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="summary_financial" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Summary Financials
              </div>
              <div v-html="memo.summary_financials" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.summary_financials_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="deal" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Deal
              </div>
              <div v-html="memo.deal" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.deal_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="outcomes_analysis" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Outcomes Analysis
              </div>
              <div v-html="memo.outcomes_analysis" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.outcomes_analysis_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="conclusion" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Conclusion
              </div>
              <div v-html="memo.conclusion" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.conclusion_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative mb-12">
              <div id="appendix" class="border-b-[1px] border-black py-[6px] text-[18px] font-Playfair font-bold leading-[24px] mb-4">
                Appendix
              </div>
              <div v-html="memo.appendix" class="mb-6 text-greyText text-sm tracking-[0.1px] pr-2"></div>
              <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                <div
                  v-for="(file, index) in memo.appendix_docs"
                  :key="index"
                  class="relative h-[230px] bg-faintGrey rounded border box-shadow">
                  <div class="flex justify-center mt-0 h-full min-h-[170px] overflow-hidden">
                    <a v-if="Helpers.getFileExtension(file.file_name)"
                      class="cursor-pointer w-full relative"
                      :href="`https://view.officeapps.live.com/op/view.aspx?src=${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                    <a v-else
                      class="cursor-pointer w-full relative"
                      :href="`${s3Bucket}${file.file_name}`"
                      :download="`${file.file_name}`"
                      target="_blank"
                    >
                      <FilePreview :filePath="`${s3Bucket}${file.file_name}`"></FilePreview>
                      <!-- <img class="w-10 h-10" :src="require(`../assets/files/${Helpers.getFileIcon(file.mime_type)}.png`)"/> -->
                    </a>
                  </div>
                  <div class="flex items-center h-[100px] w-full bg-white absolute bottom-0">
                    <div class="mx-4">
                      <h3 class="!text-sm text-black font-medium font-Rubik mb-2">{{ file.file_name }}</h3>
                      <p class="!text-xs text-greyText font-Rubik">{{getDate(file.created_at)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</LayoutHome>
</template>

<script>
import { useRoute } from 'vue-router'
import Helpers from '@/utils/helpers'
import MemoService from '@/services/memo.service'
import LayoutHome from '@/components/LayoutHome.vue'
import FilePreview from '@/components/FilePreview.vue'
import { ref } from 'vue'
export default {
  name: 'HomeView',
  components: {
    LayoutHome,
    FilePreview
  },
  setup () {
    const route = useRoute()
    const memo = ref({})
    const dataloaded = ref(false)
    const error = ref(false)
    const errorMessage = ref(null)
    const s3Bucket = process.env.VUE_APP_ORIGINS_S3_BUCKET
    const fetchMemo = async () => {
      const id = route.params.id
      try {
        const response = await MemoService.getMemobySlug(id)
        if (response.status === 200 && response.data.status === 'success') {
          dataloaded.value = true
          console.log(response.data.data)
          memo.value = response.data.data
          window.document.title = `${memo.value.company_name} Memo | Origins Memo`
          return response.data.data
        }
      } catch (e) {
        dataloaded.value = false
        error.value = true
        errorMessage.value = e.response.data.message
        console.log(e.response)
      }
    }
    const getDate = (created) => {
      const date = new Date(created.split('T')[0])
      return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date)
    }
    fetchMemo()
    return {
      memo,
      s3Bucket,
      Helpers,
      getDate,
      dataloaded,
      error
    }
  }
}
</script>

<style>
p {
white-space: p-wrap;       /* Since CSS 2.1 */
white-space: -moz-p-wrap;  /* Mozilla, since 1999 */
white-space: -p-wrap;      /* Opera 4-6 */
white-space: -o-p-wrap;    /* Opera 7 */
word-wrap: break-word;       /* Internet Explorer 5.5+ */
font-size: inherit;
border: initial;
padding: initial;
font-family: inherit;
}
.home ul {
  list-style-type: disc !important;
  list-style: disc !important;
}
.home ol {
  list-style-type: revert !important;
  list-style: revert !important;
}
.home ul,
.home ol {
  padding: 0 1rem !important;
  display: block !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 20px !important;
}
.home li {
  display: list-item;
}
.home a {
  text-decoration: underline;
  cursor: pointer;
}
.home b {
  font-weight: bold;
}
.home blockquote {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}
.home code {
  font-family: monospace;
}
.home h1,h2,h3,h4,h5,h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: normal;
}
.home h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
.home h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.home h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.home h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.home h5 {
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.home h6 {
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
.home hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}
.home s {
  text-decoration: line-through;
}
.home strong {
  font-weight: bold;
}
</style>
