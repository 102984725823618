
<template>
  <iframe class="w-[calc(100%+6px)] h-full bg-transparent scale-105" allowtransparency="true" scrolling="no" style="overflow:hidden; pointer-events:none;" :src="filePath" frameborder="0"></iframe>
</template>

<script>
export default {
  props: ['filePath'],
  setup (props) {
    console.log(props)
    return { }
  }
}
</script>

<style>

.scrollable-div::-webkit-scrollbar {
  display: none;
}

iframe #document body {
  background-color: transparent!important;
}

</style>
